<template>
  <div class="goods">
    <div class="container">
      <div class="wzc-form">
        <a-form :model="listQuery" name="horizontal_login" layout="inline" autocomplete="off" @finish="onFinish">
          <a-form-item label="发货地" name="">
            <a-input v-model:value="listQuery.fromUserAddress"/>
          </a-form-item>

          <a-form-item label="收货地" name="">
            <a-input v-model:value="listQuery.toUserAddress"/>
          </a-form-item>

          <a-form-item label="日期" name="">
            <a-range-picker v-model:value="datetime" :format="dateFormat" :locale="locale"/>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="resetForm">清空</a-button>
          </a-form-item>

        </a-form>
      </div>

      <div class="content">
        <div class="button-group">
          <a-button v-for="(item,index) in button" @click="getTypeList(item.id)"
                    v-bind:class="{'ant-btn-primary':item.id==link}">{{ item.title }}
          </a-button>
        </div>
        <div class="content-body">
          <div class="goods-box" v-for="item in list">
            <div class="box-row">
              <div class="tip" v-if="item.goodsType===1">抢</div>
              <div class="tip" v-if="item.goodsType===2" style="background-color: #FE981F">竞</div>
              <div class="tip" v-if="item.goodsType===3" style="background-color: #FE981F">秒</div>
              <div class="send" :title=" item.fromUserAddress ">{{ item.fromUserAddress }}</div>
              <div class="jt"><img src="../assets/img/jt.png"></div>
              <div class="receive" :title=" item.toUserAddress ">{{ item.toUserAddress }}</div>
              <div class="datatime">{{ item.countDownTime }}</div>
            </div>
            <div class="box-row">
              <span class="weight">{{ item.weight }}  {{ weightUnit[item.weightUnit] }}</span>
            </div>
            <div class="box-row">
              <span class="material">{{ item.materialsName }}</span>
              <div class="price" v-if="item.goodsType!==2">单价：<span>{{ item.price }}</span> 元</div>
              <div class="price" v-if="item.goodsType===2">剩余竞价：<span>{{ item.weight }}</span> {{ weightUnit[item.weightUnit] }}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="pagination">

        <a-pagination hideOnSinglePage v-model:current="listQuery.pageNum" v-model:pageSize="listQuery.pageSize"
                      :total="total"
                      @change="getList"/>

      </div>
    </div>
  </div>

</template>

<script>
import {DownOutlined, SearchOutlined, UpOutlined} from '@ant-design/icons-vue';
import "swiper/swiper.scss";
import "../styles/grid.scss"
import {getList} from '@/api/goods';
import QRcode from './common/QRcode.vue'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

dayjs.locale('zh-cn');

export default {
  name: "Goods"
  , components: {
    DownOutlined,
    UpOutlined,
    SearchOutlined,
    QRcode,
  }

  , data() {
    return {
      button: [
        {id: 0, title: '全部'},
        {id: 1, title: '抢单'},
        {id: 2, title: '竞价'},
        {id: 3, title: '秒杀'},
      ]
      , CountDown: ''
      , locale: locale
      , total: 0
      , dateFormat: 'YYYY-MM-DD'
      , list: null
      , expireTime: []
      , link: 0
      , datetime: []
      , listQuery: {
        pageNum: 1
        , pageSize: 10
        , fromUserAddress: ''
        , toUserAddress: ''
      },
      weightUnit: ['', '吨','方','箱','车','件','公里','吨/公里']
    }
  }
  , mounted() {
    this.getList()
  }
  , methods: {

    resetForm() {
      this.listQuery = {pageNum: 1, pageSize: 10}
      this.datetime = []
      this.getList()
    }
    , getList() {
      getList(this.listQuery).then(response => {
        this.list = response.data.list;
        for (let i in this.list) {
          this.list[i].countDownTime = '';
          this.countDown(i);
        }
        this.total = response.data.total;
      })
    }
    , getTypeList(goodsType) {
      this.link = goodsType
      this.listQuery = {pageNum: 1, pageSize: 10, queryType: goodsType}
      getList(this.listQuery).then(response => {
        this.list = response.data.list;
        for (let i in this.list) {
          this.list[i].countDownTime = '';
          this.countDown(i);
        }
        this.total = response.data.total;
      })
    }
    , onFinish() {
      if (this.datetime.length > 0) {
        let startTime = this.datetime[0];
        let endTime = this.datetime[1];
        let temp1 = dayjs(startTime).year() + '-' + (dayjs(startTime).month() + 1) + '-' + dayjs(startTime).date() + ' 00:00:00'
        let temp2 = dayjs(endTime).year() + '-' + (dayjs(endTime).month() + 1) + '-' + dayjs(endTime).date() + ' 23:59:59'
        this.listQuery.expiredBeginTime = temp1
        this.listQuery.expiredEndTime = temp2
      }
      this.listQuery.queryType = 0
      this.getList()
    }
    , countDownFun: function (val) {
      const endItem = new Date(val).getTime();
      const nowItem = new Date().getTime();
      const leftTime = endItem - nowItem;
      let showTime;
      if (leftTime > 0) {
        let d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
        let m = Math.floor(leftTime / 1000 / 60 % 60);
        let s = Math.floor(leftTime / 1000 % 60);
        showTime = d + " 天 " + h + " 小时 " + m + " 分 " + s + " 秒 ";
      } else {
        showTime = "已结束";
      }
      return showTime;
    }
    , countDown(i) {
      let that = this;
      let item = that.list[i];
      that.list[i].countDownFn = setInterval(() => {
        if (that.countDownFun(item.countDownTime) == "0") {
          clearInterval(that.list[i].countDownFn); //清除定时器
        } else {
          item.countDownTime = that.countDownFun(item.expireTime);
        }
      }, 1000);
    }
  }
  , destroyed() {
    clearInterval()
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 1140px !important;
  max-width: 1140px !important;
}


.goods {
  padding-top: 66px;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    height: calc((100% - 30px) / 2) !important;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

.wzc-form {
  margin-top: 40px;
  margin-bottom: 40px;

  & .gutter-row {
    padding: 5px;
  }

  & .gutter-btn {
    & > button {
      margin: 0 5px;
    }
  }
}

.button-group {
  text-align: center;

  & .ant-btn {
    font-size: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: none;
    padding: 8px 15px;
    height: auto;
  }
}


.pagination {
  display: block;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

.gutter-btn {
  text-align: center;
}

.td-style {
  background-color: #ECECEC;
}

.td-color {
  color: #FF2100;
}

.goods-box {
  background-color: #fff;
  padding: 15px;
  border-radius: 4px;
  height: 200px;
  margin-bottom: 20px;

  .box-row {
    position: relative;
    overflow: hidden;
  }
}

.tip {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #FF5354;
  text-align: center;
  line-height: 44px;
  font-size: 28px;
  color: #fff;
  float: left;
  margin-right: 15px;
}

.send, .receive {
  float: left;
  width: 293px;
  height: 44px;
  font-size: 24px;
  line-height: 44px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.jt {
  float: left;
  margin-left: 30px;
  margin-right: 30px;
}

.datatime {
  width: 200px;
  padding: 8px;
  height: 44px;
  border: 1px solid #C11D25;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  line-height: 28px;
  float: right;
  background: rgba(193, 29, 37, 0.15);
  color: #C11D25;
}

.weight {
  margin-left: 49px;
}

.weight {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 28px;
}

.material {
  position: relative;
  top: 5px;
  float: left;
  font-size: 28px;
  margin-left: 49px;
}

.price {
  position: relative;
  float: right;
  font-size: 28px;
  color: #282828;
  top: 0;

  span {
    color: #D23132;
    font-size: 32px;
  }
}
</style>