<template>
  <div class="scheme">
    <div class="banner"><img src="@/assets/img/bg/scheme_banner2.png" alt="">
      <h3>产品服务</h3>
    </div>
    <div class="container">
      <div class="product">
        <div class="item" v-for="scheme in list">
          <router-link :to="{ path: 'Article', query: { id: scheme.id, title: scheme.title, content: scheme.content } }">
            <div class=""><img :src="scheme.picture"></div>
            <div>
              <h5>{{ scheme.title }}</h5>
            </div>
            <div>
              <h6 v-html="scheme.summary"></h6>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { list } from "../api/scheme";

export default {
  name: "Scheme"
  , data() {
    return {
      list: null,
    }
  }
  , mounted() {

    const columnId = this.$route.query.columnId;
    // console.log("Scheme columnId:",columnId);
    const queryParam = { id: columnId }
    list(queryParam).then(response => {
      this.list = response.data.list;
      // console.log("Scheme list:",this.list);
    })
  }
}
</script>

<style scoped lang="scss">
.banner {
  position: relative;

  &>img {
    width: 100%;
  }

  &>h3 {
    font-size: 46px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

.product {
  padding-top: 50px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & .item {
    padding-bottom: 50px;
    width: 25%;
    text-align: center;

    & img {
      margin-bottom: 15px;
    }

    & h5 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    & h6 {
      font-size: 16px;
      color: #cccccc;
    }

  }
}
</style>