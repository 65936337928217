<template>
  <div class="about">

    <div class="intro">
      <div class="container">
 <h1>赛马物联科技（天津）有限公司</h1>
        <p>赛马物联科技（天津）有限公司（简称“赛马物联”）是一家由国务院国资委监管的
<!--           <h1>赛马物联科技（宁夏）有限公司</h1>-->
<!--        <p>赛马物联科技（宁夏）有限公司（简称“赛马物联”）是一家由国务院国资委监管的-->
          中央企业中国建材集团有限公司旗下宁夏建材集团股份有限公司（股票代码：600449）控股的智慧物流科技公司。</p>
        <p>赛马物联是一家以高新技术、创新服务为核心
          的“工业互联网+智慧物流”的平台型科技企业。主要从事供应链物流、智慧物流及工业互联网产业应用开发、集成、信息技术及推广服务，专注于服务工业互联网产业链上下游用
          户，利用人工智能、区块链、云计算、大数据等信息技术为制造业与物流业高效融合提供 数字化、智能化、集成化的软硬件综合服务方案。</p>
        <h2>我找车智慧物流平台简介</h2>
        <p>“我找车”智慧物流平台是物流运输管理系统与企业工业互联网超融合的现代物流平
          台，是面向社会的物流业、制造业深度融合创新的B2B2C共享创业平台；“我找车”融入
          前沿（IT领域）ABCD技术，引领和保障平台先进性。平台着力于制造业企业物流，服务
          于线路相对固定、多次往返的大宗整车货物运输业务和工程机械车辆物流业务场景，从企
          业采购销售端入手，利用移动互联网、人工智能、大数据、物联网、云计算、区块链等创
          新技术，创新运输组织模式，实现物流资源汇聚、共享和协同。</p>
      </div>
    </div>
<!--    <div class="timeline">-->
<!--      <div class="container">-->
<!--        <h3>发展历程</h3>-->
<!--        <a-timeline mode="alternate">-->
<!--          <a-timeline-item>-->
<!--            <h5>2018年</h5>-->
<!--            <p>“我找车”智慧物流平台正式上线</p>-->
<!--            <p>“我找车”智慧物流平台运费突破1000万元</p>-->
<!--          </a-timeline-item>-->
<!--          <a-timeline-item color="green">-->
<!--            <h5>2019年</h5>-->
<!--            <p>智慧物流平台GMV突破1亿元</p>-->
<!--            <p>入选宁夏回族自治区第一批无车承运人试点单位</p>-->
<!--          </a-timeline-item>-->

<!--          <a-timeline-item color="red">-->
<!--            <h5>2020年</h5>-->
<!--            <p>获网络货运道路运输经营许可</p>-->
<!--            <p>获28项软件著作权</p>-->
<!--            <p>平台获得国家信息保护安全等级第三级认证</p>-->
<!--            <p>平台注册车辆总数达37万辆</p>-->
<!--            <p>平台注册用户总数达26万</p>-->
<!--            <p>入选中国物流与采购联合协会副会长单位</p>-->
<!--          </a-timeline-item>-->
<!--          <a-timeline-item>-->
<!--            <h5>2021年</h5>-->
<!--            <p>赛马物联入选国务院国资委“双百企业”名单</p>-->
<!--            <p>成立赛马物联科技（天津）有限公司、湖南中联南方物联科技有限公司2家子公司</p>-->
<!--          </a-timeline-item>-->

<!--        </a-timeline>-->
<!--      </div>-->
<!--    </div>-->
    <div class="connect">
      <div class="container">
        <h3>联系我们</h3>
        <ul class="connect-item">
          <li>联系电话</li>
           <li>022-25602480</li>
<!--          <li>4008874005</li>-->
        </ul>

<!--        <ul class="connect-item">-->
<!--          <li>电子邮件</li>-->
<!--          <li>ningxiajiancai@sina.com</li>-->
<!--        </ul>-->

        <ul class="connect-item">
          <li>公司地址</li>
           <li>天津自贸试验区(东疆保税港区)澳洲路6262号查验库办公区202室</li>
<!--          <li>宁夏银川市金凤区人民广场东路219号建材大夏</li>-->
        </ul>
        <ul class="connect-item">

          <li>如果您在使用我们的产品或服务时遇到与隐私保护相关的任何事宜或任何问题都可直接与我们联系。我们专门为您提供了多种反馈通道，当收到您的反馈并验证您的用户身份后，我们会在15个工作日内或法律法规规定的期限内尽快回复您，希望为您提供满意的解决方案。</li>
        </ul>

      </div>
    </div>

  </div>
</template>
<style scoped lang="scss">
h3 {font-size: 46px;text-align: center;padding: 40px 0;}
.connect-item {
  margin-bottom: 40px;
  text-align: center;
  color: #333;
  line-height: 1.6;
  font-size: 20px;
}
.timeline {
  background-color: #f2f2f2;
  padding-bottom: 40px;
  & .ant-timeline-item-content > p {text-align: left;}
  h5 {font-size: 18px;margin-bottom: 10px;}
  & .ant-timeline-item-right p { text-align: right; }
  p {margin-bottom: 5px;font-size: 14px;}
}
.about {
  // padding: 40px 0;
  padding: 106px 0;
  & .intro {
    padding-bottom: 40px;
    & h1 {font-size: 32px;font-weight: 400; margin-bottom: 30px;}
    & h2 {font-size: 26px;font-weight: 400; margin-bottom: 20px;}
    & p {font-size: 16px;line-height: 2;text-align: justify;text-indent: 2rem;}
  }
}
</style>
