<template>
    <a-modal :visible="visible" title="二维码下载" :footer="null" @cancel="handleCancel"> 
      <div class="qrcode">
              <div class="qrcode-item">
                <h6>货主端APP</h6>
                <img src="@/assets/img/qrcode-1.png" alt="货主端APP">
              </div>
              <div class="qrcode-item">
                <h6>司机端APP</h6>
                <img src="@/assets/img/qrcode-2.png" alt="司机端APP">
              </div>
            </div>
    </a-modal>

</template>

<script>

export default {
  name: "QRcode",
  data() {
    return {

    }
  },
  methods: {
    handleCancel(e) {
      this.$emit('changeVisible', false);
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.qrcode {
      & > .qrcode-item {display: inline-block;width: 50%;padding: 5px; text-align: center;

        & > h6 {margin-bottom: 5px;font-size: 16px;font-weight: 500;}

        & > img {
          display: inherit;
          width: 106px;
          height: 106px;
          @media (min-width: 1200px) { width: 122px;
            height: 122px;}
        }
      }
    }

</style>
