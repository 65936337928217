import request from "@/utils/request";
export function getList(data) {
    return request({
        url: '/archives/portal/supply/hall/list',
        method: 'post',
        data
    })
}
export function getDetail(data) {
    return request({
        url: '/archives/portal/selectBiddInfo',
        method: 'post',
        data
    })
}

export function login(data) {
    return request({
        url: '/archives/apiUserInfo/login',
        method: 'post',
        data
    })
}