import request from "@/utils/request";
export function save(data) {
    return request({
        url: '/archives/portalCompany/addPortalCompany',
        method: 'post',
        data
    })
}
export function list(data) {
    return request({
        url: '/archives/portal/selectColumnNewsInfo',
        method: 'post',
        data
    })
}