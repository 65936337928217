import request from "@/utils/request";
export function provinces(data) {
    return request({
        url: '/archives/region/findRegion',
        method: 'post',
        data
    })
}
export function citys(data) {
    return request({
        url: '/archives/region/selectRegion',
        method: 'post',
        data
    })
}
export function areaitem(data) {
    return request({
        url: '/archives/region/selectRegion',
        method: 'post',
        data
    })
}
export function addcompany(data) {
    return request({
        url: '/archives/company/addNew',
        method: 'post',
        data
    })
}